import { ethers } from "ethers";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Home } from "./layout";
import {
  PrivacyPage,
  TermsServices,
  RefundPolicy,
  Media,
  Promotions,
  Store,
  TrainingModule,
  OpportunityModule,
} from "./modules";
import { MembersLanding } from "./modules/membersportal/memberslanding";
import Protected from "./layout/header/Protected";
import abi from "./abi/abi.json";
import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react"; // Combined the import statements for useState and useEffect for cleaner code.
import DashboardPage from "./modules/membersportal/dashboard";

function App() {
  const { account, library } = useWeb3React();
  var [visible, setVisible] = useState(false);
  const contract_address = abi.contractAddress;
  const navigate = useNavigate(); // Correct placement

  useEffect(() => {
    const lastPath = sessionStorage.getItem("lastPath");
    if (lastPath) {
      navigate(lastPath);
      sessionStorage.removeItem("lastPath"); // Clean up
    }
  }, [navigate]); // Depends on navigate

  useEffect(() => {
    (async () => {
      if (!library || !account) return;

      // Check session storage for NFT verification
      const isVerified = sessionStorage.getItem('nftVerified');
      if (isVerified) {
        setVisible(true);
        return; // Skip NFT balance check if already verified in this session
      }

      await window.ethereum.request({ method: "eth_requestAccounts" });
      const contract = new ethers.Contract(
        contract_address,
        abi.abi,
        library.getSigner()
      );
      const balance = await contract.balanceOf(account);
      console.log("🚀 ~ NFT", Number(String(balance)));

      if (Number(String(balance)) > 0) {
        setVisible(true);
        // Store verification status in session storage
        sessionStorage.setItem('nftVerified', 'true');
      }
    })();
  }, [account, library]); // Added library to the dependency array to ensure useEffect is called when it changes.


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home visible={visible} />} />
        <Route exact path={"/privacy-policy"} element={<PrivacyPage visible={visible} />} />
        <Route exact path={"/terms-service"} element={<TermsServices visible={visible} />} />
        <Route exact path={"/refund-policy"} element={<RefundPolicy visible={visible} />} />
        {/* <Route path="/dcg-metaverse" element={<Metaverse visible={visible} />} /> */}
        <Route exact path={"/dcg-promotions"} element={<Promotions visible={visible} />} />
        {/* ==================== */}
        <Route
          path="/portal"
          element={
            <Protected visible={visible}>
              <MembersLanding visible={visible} />
            </Protected>
          }
        />
        
        <Route
          path="/dashboard"
          element={
            <Protected visible={visible}>
              <DashboardPage visible={visible} />
            </Protected>
          }
        />
        <Route
          path="/media"
          element={
            <Protected visible={visible}>
              <Media visible={visible} />
            </Protected>
          }
        />
        <Route
          path="/store"
          element={
            <Protected visible={visible}>
              <Store visible={visible} />
            </Protected>
          }
        />
        <Route
          path="/training"
          element={
            <Protected visible={visible}>
              <TrainingModule visible={visible} />
            </Protected>
          }
        />
        <Route
          path="/opportunity"
          element={
            <Protected visible={visible}>
              <OpportunityModule visible={visible} />
            </Protected>
          }
        />
        {/* ==================== */}
        {/* <Route path="/media" element={<Media />} />
        <Route path="/archive" element={<Archives />} /> */}
      </Routes>
    </div>
  );
}

export default App;
