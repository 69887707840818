import React,{useState, useEffect} from "react";
import "./scss/index.scss";
import Web3, { Contract } from "web3";
import logo from "../../assets/images/logogsg.png";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Setup, useEagerConnect } from '../../Hooks';
import contractABI from "../../abi/contractABI"; // Confirm the correct path and format
import useCryptoPrice from '../../Hooks/useCryptoPrice';
import { InjectedConnector } from "@web3-react/injected-connector";
import { NavLink } from "react-router-dom";
import { Sidebar } from "../sidebar";
import { GiHamburgerMenu } from "react-icons/gi";
// import TradingViewWidget from './ticker'; // Adjust the path as necessary


export const Header = ({ history, visible }) => {
  const { contract, isContractReady, getNetworkSymbol, contractAddresses } = useWeb3Setup();
  const [gs50Price, setGs50Price] = useState('Loading...');
  const { activate, active, account, chainId, library } = useWeb3React();
  const symbol = getNetworkSymbol(chainId);
  const [isOpen, setIsopen] = useState(false);
  const [gs50PriceInUsd, setGs50PriceInUsd] = useState("Loading...");
  const { cryptoPrice } = useCryptoPrice(chainId);

  const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137],
  });
  

  
  useEagerConnect();
  useEffect(() => {
    const initializeContracts = async () => {
      if (active && library && chainId && contractAddresses[chainId]) {

      const web3 = new Web3(library.provider);
      const contractAddress = contractAddresses[chainId]; // Ensure this is your contract address

      if (!contractAddress) {
        console.error(`Contract address not found for chain ID: ${chainId}`);
        return;
      }

      try {
        const contractInstance = new web3.eth.Contract(
          contractABI,
          contractAddress
        );
        const priceInWei = await contractInstance.methods.buyPrice().call();
        const priceInEther = Web3.utils.fromWei(priceInWei, "ether");
        setGs50Price(priceInEther);
      } catch (error) {
        console.error("Error initializing contract or fetching price:", error);
      }
    }
    };
    

    initializeContracts();
  }, [active, library, account]);
  // console.log("index", visible);
  const metamask = async () => {
    try {
      await activate(injected);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    const fetchGs50Price = async () => {
      if (!isContractReady || !contract) {
        console.log("Contract is not ready or unavailable.");
        return;
      }
  
      try {
        const priceInWei = await contract.methods.buyPrice().call();
        const priceInEther = Web3.utils.fromWei(priceInWei, 'ether');
        setGs50Price(priceInEther);
      } catch (error) {
        console.error("Error fetching GS50 price:", error);
        setGs50Price('Error');
      }
    };
  
    fetchGs50Price();
  }, [contract, isContractReady]);

  useEffect(() => {
    if (gs50Price !== "Loading..." && cryptoPrice !== "Loading...") {
      const cryptoPriceStr = String(cryptoPrice); // Convert to string to ensure .replace works
      const cryptoPriceInUsd = parseFloat(cryptoPriceStr.replace("$", "").replace(" USD", ""));
      const gs50PriceInUsdValue = parseFloat(gs50Price) * cryptoPriceInUsd;
      setGs50PriceInUsd(`$${gs50PriceInUsdValue.toFixed(2)}`);
    }
  }, [gs50Price, cryptoPrice]);
  
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <div className="navbar_Section">
      <div className="nested_navbarSection">
        <div className="navbar_contentSection">
          <div className="logo">
            <NavLink to={"/"}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="prices">
            <div className="price-item">
              {symbol} PRICE: <span style={{ color: "green" }}>{cryptoPrice}</span>
            </div>
            <div className="price-item">
              GS50 PRICE: <span>{gs50PriceInUsd}</span>
            </div>
          </div>
          <div className="links">
            <NavLink
              className={"px-3"}
              to={"https://www.facebook.com/jamarjames11"}
              target="_blank"
            >
              Facebook
            </NavLink>
            <NavLink
              className={"px-3"}
              to={"https://www.instagram.com/jamarjames/"}
              target="_blank"
            >
              Instagram
            </NavLink>
            {/* <NavLink className={"px-3"} to={"/dcg-metaverse"} target="_blank">
              DCG Metaverse
            </NavLink> */}
            <NavLink className={"px-3"} to={"/dcg-promotions"}>
              Discord Access
            </NavLink>
            {visible ? (
              <div className="dropdown">
                <NavLink to= "/portal" className="dropdown-toggle">Members Portal</NavLink>
                {/* <div className="dropdown-content">
                  <>
                    <NavLink to="/dashboard">Dashboard</NavLink>
                    <NavLink to="/media">Media Center</NavLink>
                    <NavLink to="/opportunity">Opportunities</NavLink>
                    <NavLink to="/store">Store</NavLink>
                    <NavLink to="/training">Training</NavLink>
                  </>
                </div> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="btn_header">
            {!active ? (
              <button onClick={metamask}>Connect</button>
            ) : (
              <button>
                {account === undefined
                  ? "Connect Wallet"
                  : account === null
                  ? "None"
                  : `${account.substring(0, 6)}...${account.substring(
                      account.length - 4
                    )}`}
              </button>
            )}
                      <div className="btn-primary showButton p-2 text-base rounded-md cursor-pointer" onClick={ToggleSidebar}>
              {/* <i className="fa fa-bars"></i> */}
              <GiHamburgerMenu  />
            </div>
          </div>



          
        </div>
      </div>
      <Sidebar ToggleSidebar={ToggleSidebar} isOpen={isOpen} visible={visible} />
      {/* <TradingViewWidget /> */}
    </div>

    
  );
};