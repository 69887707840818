import React, { useState, useEffect } from 'react';
import { Header, Footer } from '../../../layout/';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../dashboard/scss/index.scss';
import { useWeb3React } from '@web3-react/core';
import { useWeb3Setup } from '../../../Hooks/';
import Web3 from 'web3';
import useCryptoPrice from '../../../Hooks/useCryptoPrice';
import avaxLogo from '../../../assets/images/networks/avax.png';
import baseLogo from '../../../assets/images/networks/base.png';
import bnbLogo from '../../../assets/images/networks/bnb.png';
import ethLogo from '../../../assets/images/networks/eth.png';
import polygonLogo from '../../../assets/images/networks/polygon.png';
import Logo from '../../../assets/images/networks/8020.png';
const DashboardPage = ({ history, visible }) => {
  
  const { account, chainId } = useWeb3React();
  const { contract: contractInstance, isContractReady, getNetworkLogo, getNetworkSymbol } = useWeb3Setup();
  const { cryptoPrice } = useCryptoPrice(chainId);
  const networkLogo = getNetworkLogo(chainId);
  const networkSymbol = getNetworkSymbol(chainId);
  const [refAddress, setRefAddress] = useState("loading");
  const [buyAmount, setBuyAmount] = useState('');
  const navigate = useNavigate();
  const [sellAmount, setSellAmount] = useState('');
  const [userInfo, setUserInfo] = useState({
    gs50Held: 'Loading...',
    rewardsEarned: 'Loading...',
    bonusEarned: 'Loading...',
    commissionEarned: 'Loading...',
    totalEarned: 'Loading...',
    totalSupply: 'Loading...'
  });

  
  useEffect(() => {
    if (!account) {

      return;
    }

    const fetchUserInfo = async () => {
      if (!contractInstance || !isContractReady) {
        
        return;
      }

      try {
        const gs50HeldWei = await contractInstance.methods.balanceOf(account).call();
        const rewardsEarnedWei = await contractInstance.methods.dividendsOf(account).call();
        const bonusEarnedWei = await contractInstance.methods.loyaltyOf().call({ from: account });
        const referralEarnedWei = await contractInstance.methods.referralBalance_(account).call();
        const gs50HeldEther = Web3.utils.fromWei(gs50HeldWei, 'ether');
        const formattedGs50Held = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2
        }).format(parseFloat(gs50HeldEther));
        const rewardsEarnedEther = Web3.utils.fromWei(rewardsEarnedWei, 'ether');
        const bonusEarnedEther = Web3.utils.fromWei(bonusEarnedWei, 'ether');
        const referralEarnedEther = Web3.utils.fromWei(referralEarnedWei, 'ether');
        const totalSupplyWei = await contractInstance.methods.totalSupply().call();
        const totalSupplyEther = Web3.utils.fromWei(totalSupplyWei, 'ether');
        const formattedTotalSupply = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2
        }).format(parseFloat(totalSupplyEther))


        const formatNumber = (number) => {
          return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
          }).format(number);
        };
        
        // Summing the values
        const totalEarnedEther = parseFloat(rewardsEarnedEther) + parseFloat(bonusEarnedEther) + parseFloat(referralEarnedEther);
  
        // Formatting the total to two decimal places
        const formattedTotalEarned = totalEarnedEther;

        const numericCryptoPrice = parseFloat(cryptoPrice); // Convert to number if necessary

  
        setUserInfo({
          gs50Held: formattedGs50Held,
          totalSupply: formattedTotalSupply,
          rewardsEarned: `$${(parseFloat(rewardsEarnedEther) * numericCryptoPrice).toFixed(4)}`,
          bonusEarned: `$${(parseFloat(bonusEarnedEther) * numericCryptoPrice).toFixed(4)}`,
          referralEarned: `$${(parseFloat(referralEarnedEther) * numericCryptoPrice).toFixed(4)}`,
          totalEarned: `$${(parseFloat(formattedTotalEarned) * numericCryptoPrice).toFixed(4)}`,
          rewardsEarnedEther: `${parseFloat(rewardsEarnedEther).toFixed(5)}`,
          bonusEarnedEther: `${parseFloat(bonusEarnedEther).toFixed(5)}`,
          referralEarnedEther: `${parseFloat(referralEarnedEther).toFixed(5)}`,
          totalEarnedEther: `${parseFloat(totalEarnedEther).toFixed(5)}`,
  

        });
      } catch (error) {
        // console.error('Error fetching user info:', error);
      }
    };
  
    fetchUserInfo();
  }, [contractInstance, isContractReady, account, cryptoPrice]);

  
const buyToken = async () => {
  // console.log("Inside buyToken, buy amount is:", buyAmount);
  if (contractInstance && buyAmount) {
    const amountInWei = Web3.utils.toWei(buyAmount.toString(), 'ether');
    if (buyAmount === 0) {
      alert("ERROR: no amount");
      return;
    }

    let overrides = {
      from: account,
      value: amountInWei, // The amount of ether to send with the transaction
    };

    // Hardcoded fallback referral address
    const fallbackRefAddress = "0xfcE153Da366d5D65aa04d0a53F1110dF9e074834";

    // Use refAddress if it's loaded and valid; otherwise, use the fallback address
    const effectiveRefAddress = refAddress && refAddress !== "loading" && refAddress !== "0x0000000000000000000000000000000000000000" ? refAddress : fallbackRefAddress;

    try {
      const tx = await contractInstance.methods.buy(effectiveRefAddress).send(overrides);
      console.log("Transaction Completed!", tx);
    } catch (error) {
      console.error('Error buying tokens:', error);
      // Handle errors appropriately
    }
  } else {
    alert("ERROR: no amount specified or contract not initialized");
  }
};

  
  

const sellToken = async () => {
  // console.log("Inside sellToken, sell amount is:", sellAmount);
  if (contractInstance && sellAmount) {
    const amountInTokens = Web3.utils.toWei(sellAmount.toString(), 'ether'); // Assuming the token uses 18 decimal places like Ether
    if (sellAmount === 0) {
      alert("ERROR: no amount");
      return;
    }

    try {
      const tx = await contractInstance.methods.sell(amountInTokens).send({ from: account });
      console.log("Sell Transaction Successful", tx);
    } catch (error) {
      console.error('Error selling tokens:', error);
      // Handle errors appropriately
    }
  } else {
    alert("ERROR: no amount specified or contract not initialized");
  }
};

const claimRewards = async () => {
  if (contractInstance) {
    try {
      const tx = await contractInstance.methods.withdraw().send({ from: account });
      await tx.wait(); // Wait for the transaction to be mined
      console.log("Rewards successfully claimed");
      // Consider updating the UI to reflect the change instead of reloading the page
      // window.location.reload();
    } catch (error) {
      console.error('Error claiming rewards:', error);
      // Handle errors appropriately, maybe show a message to the user
    }
  } else {
    alert("Contract not initialized");
  }
};

  

const compoundRewards = async () => {
  if (contractInstance) {
    try {
      const tx = await contractInstance.methods.reinvest().send({ from: account });
      await tx.wait(); // Wait for the transaction to be mined
      console.log("Rewards successfully compounded");
      // Consider updating the UI to reflect the change instead of reloading the page
      // window.location.reload();
    } catch (error) {
      if (error.code === 4001) {
        // User rejected the transaction
        console.log("Transaction rejected by user");
        return;
      }
      console.error('Error compounding rewards:', error);
      // Handle errors appropriately, maybe show a message to the user
    }
  } else {
    alert("Contract not initialized");
  }
};


const copyToClipboard = () => {
  // Create a new textarea element
  const el = document.createElement("textarea");

  // Optional: Style the textarea to be unobtrusive
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  // Set the value to the referral link you wish to copy
  el.value = `http://dcgelitenft.com/dashboard?ref=${account}`;

  // Append the textarea to the document body
  document.body.appendChild(el);

  // Select the content of the textarea
  el.select();

  // Copy the selected content to the clipboard
  document.execCommand("copy");

  // Remove the textarea from the document body
  document.body.removeChild(el);

  // Optional: Notify the user that the text has been copied
  alert("Referral link copied to clipboard!");
};

  

const navigateBack = () => {
  navigate('/portal'); // Navigate programmatically to '/portal'
};


  return (
    <>
      <Header visible={visible}/>
      <div className="dashboard-page-content">
        <div className="info-display">
        <div className="info-item-container">
          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>GS50 Balance: </label><span>{userInfo.gs50Held}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>Circulating Supply: </label><span>{userInfo.totalSupply} </span>
          </div>

          <div className="info-item rewards-earned">
          <label style={{ fontWeight: 'bold' }}>Rewards Earned: </label><span>{userInfo.rewardsEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{networkSymbol} </label><span>{userInfo.rewardsEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Bonus Earned: </label><span>{userInfo.bonusEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{networkSymbol} </label><span>{userInfo.bonusEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Commission Earned: </label><span>{userInfo.referralEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{networkSymbol} </label><span>{userInfo.referralEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Total Earned: </label><span>{userInfo.totalEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{networkSymbol}</label><span>{userInfo.totalEarnedEther}</span>
          </div>
          </div>


          <div className="network-logos">
          <img style={{ filter: chainId === 43114 ? 'none' : 'grayscale(100%)' }}
          src={avaxLogo}
          alt="Avalanche"/>
          <img style={{ filter: chainId === 8453 ? 'none' : 'grayscale(100%)' }}
          src={baseLogo}
          alt="Base"/>
          <img style={{ filter: chainId === 56 ? 'none' : 'grayscale(100%)' }}
          src={bnbLogo}
          alt="Binance Smart Chain"/>
          <img style={{ filter: chainId === 1 ? 'none' : 'grayscale(100%)' }}
          src={ethLogo}
          alt="Ethereum"/>
          <img style={{ filter: chainId === 137 ? 'none' : 'grayscale(100%)' }}
          src={polygonLogo} alt="Polygon"/>
    
        </div>
        </div>
        
        <div className="trading-area">
        <div className="left-buttons">
          <div className="input-button-group">
            <input
              id="swapEth"
              // ref={swapEth}
              type="number"
              value={buyAmount}
              onChange={(e) => setBuyAmount(e.target.value)}
              placeholder={`Amount in ${networkSymbol}`}
            />
            <button onClick={() => {
                buyToken();
                }}>Buy GS50</button>
            
          </div>

         
          <div className="divider"></div>

          <div className="input-button-group">
            <input
              type="number"
              value={sellAmount}
              onChange={(e) => setSellAmount(e.target.value)}
              placeholder="Amount in GS50"
            />
            <button onClick={sellToken}>Sell GS50</button>
            </div>
          </div>

          <div className="right-buttons">
          <div className="input-button-group">
            <button onClick={claimRewards}>Claim</button>
            <button onClick={compoundRewards}>Compound</button>
            <button onClick={copyToClipboard}>Copy Link</button>
            <a href="http://www.the8020.org" target="_blank" rel="noopener noreferrer">
  <img src={Logo} alt="Powered By" className="network-logo-image" />
</a>
          </div>
          </div>
        </div>
        <div className='component-footer'>
          <div className="close-icon" onClick={navigateBack}>
          <FontAwesomeIcon icon={faTimes} /> Close Dashboard
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardPage;
