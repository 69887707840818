import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import contractABI from "../abi/contractABI";
import contractAddresses from "../contracts/contractAddresses";
import { injected } from "../connectors";
import ethLogo from '../assets/images/networks/eth.png';
import bnbLogo from '../assets/images/networks/bnb.png';
import polygonLogo from '../assets/images/networks/polygon.png';
import baseLogo from '../assets/images/networks/base.png';
import avaxLogo from '../assets/images/networks/avax.png';


const SUPPORTED_CHAIN_IDS = [1, 56, 137, 43114, 8453];

// console.log("Logo is populated", networkLogos)


  const networkLogos = {
    1: ethLogo,
    56: bnbLogo,
    137: polygonLogo,
    8453: baseLogo,
    43114: avaxLogo,
  };

  const networkSymbol = {
    1: "ETH",
    56: "BNB",
    137: "MATIC",
    8453: "ETH",
    43114: "AVAX",
  };

  export const useWeb3Setup = () => {
    const { active, library, chainId, account, activate } = useWeb3React();
  
  const [contract, setContract] = useState(null);
  const [isContractReady, setIsContractReady] = useState(false);
  const [currentChainId, setCurrentChainId] = useState(null);

  useEffect(() => {
    if (chainId) { // Make sure chainId is not null or undefined
      console.log("chainId is available:", chainId);
      getNetworkLogo(chainId);
      setCurrentChainId(chainId);
    }
  }, [chainId]); // Depend on chainId to trigger this effect
  

  const getNetworkSymbol = (chainId) => networkSymbol[chainId] || "Unknown";

  const getNetworkLogo = (chainId) => {
  // console.log("Received chainId in getNetworkLogo:", chainId);
  const logoPath = networkLogos[chainId] || bnbLogo; // Default logo if the chainId is not found
  // console.log("LOST CHAIN ID", chainId); // Log the chainId to see which one is being passed
  // console.log("Logo path:", logoPath); // Log the resolved logo path to verify the correct image is selected
  return logoPath;
  
};
useEffect(() => {
  if (active && library && SUPPORTED_CHAIN_IDS.includes(chainId)) {
    const web3 = new Web3(library.provider);
    const address = contractAddresses[chainId];
    
    if (address) {
      const newContract = new web3.eth.Contract(contractABI, address);
      setContract(newContract);
      setIsContractReady(true);
    } else {
      console.warn(`No contract address found for chain ID ${chainId}`);
      setIsContractReady(false);
    }
  } else {
    setIsContractReady(false);
  }
}, [active, library, chainId]);

return { currentChainId, setCurrentChainId, contract, isContractReady, account, getNetworkLogo, getNetworkSymbol };
};

export function useEagerConnect() {
const { activate, active } = useWeb3React();
const [tried, setTried] = useState(false);

useEffect(() => {
  injected.isAuthorized().then((isAuthorized) => {
    if (isAuthorized) {
      activate(injected, undefined, true).catch(() => {
        setTried(true);
      });
    } else {
      setTried(true);
    }
  });
}, [activate]); // Run only on mount

useEffect(() => {
  const { ethereum } = window;

  const onChainChanged = (chainId) => {
    window.location.reload();
  };

  ethereum.on("chainChanged", onChainChanged);

  const onAccountsChanged = (accounts) => {
    if (accounts.length > 0) {
      activate(injected);
    }
    setTried(true);
  };

  ethereum.on("accountsChanged", onAccountsChanged);

  return () => {
    ethereum.removeListener("chainChanged", onChainChanged);
    ethereum.removeListener("accountsChanged", onAccountsChanged);
  };
}, [activate]); // Run only on mount

useEffect(() => {
  if (!tried && active) {
    setTried(true);
  }
}, [tried, active]);

return tried;
}