import React from 'react';
import { Header, Footer } from "../../../layout";
import MuxPlayer from '@mux/mux-player-react';

export const Media = ({visible}) => {
  const options = {
    
    playbackId:"XbTfs3VPu7yilqbR5cZYQm8FhTLUxfgnLbcm1ImcrBc",
    // apiKey: 'c324ec49-eb56-4a89-8b06-bc8f6a7a82fe',
    // secret: '8VjBW08E5ibug6rOr3WFMLEFUk487O/seg91JyieJbK8p3j3y4UgHRgAPR+ibqf4B/WbeZkeK6j',
    src: 'https://stream.mux.com/XbTfs3VPu7yilqbR5cZYQm8FhTLUxfgnLbcm1ImcrBc/.m3u8',
  };
  return (
    <div>
        <Header visible={visible}/>
        <div className='mt-20 -mb-2'>
        <MuxPlayer {...options} />
        </div>
        <Footer/>
        
        </div>
  )
}
