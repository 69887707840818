import React, { useState, useRef } from "react";
import "./scss/index.scss";
import logo from "../../../assets/images/logogsg.png";
import { MdEmail } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { BsFillCalendarFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
// import emailjs from "@emailjs/browser";
import 'react-responsive-modal/styles.css';
import { NavLink } from "react-router-dom";

export const ContactUs = () => {
  const form = useRef();



  const [contactobj, setContactObj] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    message: "",
  });
  

  const getDetailsInput = (e) => {
    // e.preventDefault();
    setContactObj({ ...contactobj, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const config = {
      Username: "thegoldstandardgrp@gmail.com",
      Password: "C62B446BC9EBF7C5816F273CAD7761276D48",
      Host: "smtp.elasticemail.com",
      Port: "2525",
      To: "thegoldstandardgrp@gmail.com",
      From: "thegoldstandardgrp@gmail.com",
      Subject: "DCG EliteNFT Contact",
      Body: `Name:${contactobj.user_name} <br/> Email:${contactobj.user_email} <br/> Phone Number: ${contactobj.user_number} <br/> Message: ${contactobj.message}`,
    };
    if (window.Email) {
      window.Email.send(config).then((res) =>{
        console.log(res);
        e.target.reset();
      }, (error) => {
          console.log(error.text);
      })
    }

    // emailjs.sendForm('service_r1ny728', 'template_2o11djp', form.current, '3Y_hQR2i5t3-muWrP')
    //   .then((result) => {
    //       console.log(result.text);
    //       // console.log("Successfully sent");
    //       e.target.reset();
    //   }, (error) => {
    //       console.log(error.text);
    //   });
  };
  
  return (
    <>
    {/* <div className="get_set_regiter">
      <div>
         <h3>Get Yourself Ready To Attend <br /> <span>Register!</span> </h3>
      </div>
      <div className="btn_reg">
         <button onClick={onOpenModal}>Register Yourself!</button>
         </div>
      </div> */}
    <div className="contactus_section w-full py-10">
      <div className="nested_section">
        <div className="content_contact">
          <div className="contact_data">
            <h1>
              Become a member of the top Crypto and Stock trading group in
              America.
            </h1>
            <p>
              Verified DCG NFT holders will have access to whitelist raffles,
              rights to vote on metaverse projects, community lead suggestions
              and gain access to live market updates via proven successful
              traders.
            </p>
            <NavLink to={"https://dcgelite.com/the-door-report"} target="_blank" >
                  Learn More
                </NavLink>
          </div>
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        {/* form */}
        <div className="form py-10">
          <div className="heading_form">
            <h1>Contact Us</h1>
          </div>
          <div className="nested_form flex justify-between">
            <ul className="mt-4">
              <li>
                <MdEmail />
                <a href="mailto:jamar@mastermindculture.com">
                  {" "}
                  jamar@mastermindculture.com
                </a>
              </li>
              <li>
                <AiFillPhone />
                <a href="tel:+1(800) 965-1572"> +1(800) 965-1572</a>
              </li>
              <li>
                <BsFillCalendarFill />
                <p>Mastermind Culture, LLC</p>
              </li>
              <li>
                <MdLocationOn />
                <p>16192 Costal Highway Lewes, DE 19958 </p>
              </li>
            </ul>
            <div className="form_input py-5">
              <form onSubmit={sendEmail} ref={form}>
                <div>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    name={"user_name"}
                    onChange={getDetailsInput}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    name={"user_email"}
                    onChange={getDetailsInput}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="number">Phone</label>
                  <input
                    type="number"
                    id="number"
                    placeholder="Phone Number"
                    name={"user_number"}
                    onChange={getDetailsInput}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  {/* <input type="text" id="name" placeholder="Username" /> */}
                  <textarea
                    id="message"
                    rows="3"
                    placeholder="Message"
                    name={"message"}
                    onChange={getDetailsInput}
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="modal_registration">
    <Modal open={open} onClose={onCloseModal} center>
        <div className="form_input py-5">
              <form onSubmit={onSubmitReg} ref={refForm}>
                <div>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    name={"user_name"}
                    onChange={getDetailsRegitration}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    name={"user_email"}
                    onChange={getDetailsRegitration}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="number">Phone</label>
                  <input
                    type="number"
                    id="number"
                    placeholder="Phone Number"
                    name={"user_number"}
                    onChange={getDetailsRegitration}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    rows="3"
                    placeholder="Any thing would you like to tell us"
                    name={"message"}
                    onChange={getDetailsRegitration}
                    required
                  ></textarea>
                </div>
                <div className="">

                </div>
                <div className="my-3">
                  <button type="submit" onClick={onCloseModal}>Submit</button>
                </div>
              </form>
            </div>
      </Modal>
      </div> */}
    </>
  );
};
