import React from "react";
import "./scss/index.scss";
import {
  JamarJames,
  Darrell,
  NickHenderson,
  BrotherMoe,
  CryptoBlack,
  RicoC,
  Dennis,
  Willette,
  mike,
  BrandonH,
  RandyBell,
} from "../../../assets";
import { MintProcess } from "../mintpasses";

const teamMember=[
    {id: 1, img: JamarJames, name: "Jamar James", jobdesc: "Founder and creator"},
    {id: 2, img: NickHenderson, name: "Nick Henderson", jobdesc: "DCG Partner and Technical coach"},
    {id: 3, img: RandyBell, name: "Randy Bell", jobdesc: "DCG Elite Trader and Coach"},
    {id: 4, img: BrotherMoe, name: "Brother Moe", jobdesc: "DCG Elite NFT Specialist"},
    {id: 5, img: CryptoBlack, name: "Crypto Black", jobdesc: "DCG Elite Trader and Partner"},
    {id: 6, img: RicoC, name: "Rico C.", jobdesc: "Developer and Trading Partner"},
    {id: 7, img: Dennis, name: "Dennis S.", jobdesc: "Mastermind Team Member"},
    {id: 8, img: Willette, name: "Willette", jobdesc: "DCG Elite Member"},
    {id: 9, img: mike, name: "Mike Miller", jobdesc: "DCG Partner and Day Trader"},
    {id: 10, img: BrandonH, name: "Brandon H.", jobdesc: "DCG Elite Member and Coach"},
    

]


export const Team = () => {
  return (
    <div className="team_section w-full py-10">
      <div className="nested_section">
        {/* Team Section Content */}
        <div className="team_sectionone">
          <h1>The Mastermind Network</h1>
          <p className="py-4">
            NFT Card will give you access to join the mastermind network. Your
            token allows you to explore our private information network,
            comprised of multiple 7-figure traders, analysts, and sector
            specialists. Every base in the trading world is covered.
          </p>
          <div className="team_member">
            {teamMember.map((value) => {
              return (
                <div className="childTeam p-5" key={value.id}>
                  <div className="teammemeber flex gap-3 items-center">
                    <img src={value.img} alt={value.name} />
                    <div>
                      <p>{value.name}</p>
                      <p>{value.jobdesc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
