import React from "react";
import { Header, Footer } from "../../../layout";
import TrainingVideos from './training-video.js'; // Adjust the path based on your project structure
import "./scss/index.scss";

export const TrainingModule = ({ visible }) => {
  return (
    <div>
      <Header visible={visible} />
      <div className="archive_section">
        {/* <h1 className="page-title">Training Videos</h1> */}
       
        <TrainingVideos />
      </div>
      <Footer />
    </div>
  );
};