import React from "react";
import { Header, Footer } from "../.././../layout";
import OpportunityPage from './opportunity-list'; // Adjust the path based on your project structure
import "./scss/index.scss";

export const OpportunityModule = ({ visible }) => {
  return (
    <div>
      <Header visible={visible} />
      <div className="archive_section">
        <h1 className="page-title">Income Opportunities</h1>
        {/* <p className="page-description">
        Welcome to our income module. Here you will find a collection of income producing opportunities.
        </p> */}
        <OpportunityPage />
        
      </div>
      
      <Footer />
    </div>
  );
};