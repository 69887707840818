import React from "react";
import "./scss/index.scss";
import { NavLink } from "react-router-dom";
import {
  gsg,
  radio,
  llama,
  // jayz,
  // The_Star_of_The_Show_Thumb,
  the8020,
  sophia,
  // nonamethumb_1,
  // series,
  // Demo_new,
} from "../../../assets/index";

// nipsey,
  // jayz,
  // NewGalleryPass_fabian,
  // nonamethumb_1,
  // series,
  // Demo_new
export const Projects = () => {
  return (
    <div className="w-full py-10 project_section">
      <div className="nested_project">
        <h1 className="py-4">Partner Projects</h1>
        <div className="project_cataloge">
          <div className="projectCard">
            <img src={gsg} alt="imgownerLogo1" loading="lazy" />
            <p className="desc">GSG Digital Network</p>
            <div className="flex justify-between items-center">
              <p>Multi Chain</p>
             
              {/* </div></div><NavLink to "https://goldstandardgrp.com">Vist Site</NavLink> */}
              <NavLink to={"https://goldstandardgrp.com"} target="_blank" >
                  Visit Site
                </NavLink>
              
            </div>
          </div>
          <div className="projectCard">
            {<img src={radio} alt="Radio Network" loading="lazy" /> }
            <p className="desc">Blockchain Radio NFT</p>
            <div className="flex justify-between items-center">
              <p>Binance</p>
              <NavLink to={"https://goldstandardgrp.com/pages/radionetwork.html"} target="_blank" >
                  Visit Site
                </NavLink>
            </div>
          </div>
          <div className="projectCard">
            <img src={the8020} alt="The 8020 Social Defi" loading="lazy" />
            <p className="desc">The 80/20 Social Defi</p>
            <div className="flex justify-between items-center">
              <p>Multi Chain</p>
              <NavLink to={"https://the8020.org"} target="_blank" >
                  Visit Site
                </NavLink>
            </div>
          </div>
          <div className="projectCard">
            {<img src={sophia} alt="AI Project" loading="lazy" />}
            <p className="desc">One Human Consciousness</p>
            <div className="flex justify-between items-center">
              <p>Polygon</p>
              <NavLink to="https://goldstandardgrp.com/onehumanity/"  target={"_blank"}>Visit Now</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
