import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import useCryptoPrice from '../../../Hooks/useCryptoPrice';
import { useWeb3Setup, useEagerConnect} from '../../../Hooks';
import Web3 from 'web3';

import '../store/scss/index.scss';

const ProductCard = ({ onSelect }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { contract, isContractReady, contractAddresses } = useWeb3Setup();
  const { chainId } = useWeb3React();
  const { cryptoPrice } = useCryptoPrice(chainId);
  const { getNetworkSymbol } = useWeb3Setup();
  const networkSymbol = getNetworkSymbol(chainId);
  const [gs50Price, setGs50Price] = useState('Loading...');
  const [gs50PriceInUsd, setGs50PriceInUsd] = useState("Loading...");

  useEffect(() => {
    const fetchGs50Price = async () => {
      if (!isContractReady || !contract) {
        console.log("Contract is not ready or unavailable.");
        return;
      }
  
      try {
        const priceInWei = await contract.methods.buyPrice().call();
        const priceInEther = Web3.utils.fromWei(priceInWei, 'ether');
        setGs50Price(priceInEther);
      } catch (error) {
        console.error("Error fetching GS50 price:", error);
        setGs50Price('Error');
      }
    };
  
    fetchGs50Price();
  }, [contract, isContractReady]);

  useEffect(() => {
    if (gs50Price !== "Loading..." && cryptoPrice !== "Loading...") {
      const cryptoPriceStr = String(cryptoPrice); // Convert to string to ensure .replace works
      const cryptoPriceInUsd = parseFloat(cryptoPriceStr.replace("$", "").replace(" USD", ""));
      const gs50PriceInUsdValue = parseFloat(gs50Price) * cryptoPriceInUsd;
      setGs50PriceInUsd(`$${gs50PriceInUsdValue.toFixed(2)}`);
    }
  }, [gs50Price, cryptoPrice]);
  

  useEffect(() => {
    fetch('/data-source/products.json')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching product data:', error));
  }, []);

  const handleSelect = (id) => {
    const product = products.find(product => product.id === id);
    if (!product) return;

    const isAlreadySelected = selectedProducts.find(p => p.id === id);

    if (isAlreadySelected) {
      setSelectedProducts(current => current.filter(p => p.id !== id));
      setTotalPrice(current => current - product.price);
    } else {
      setSelectedProducts(current => [...current, product]);
      setTotalPrice(current => current + product.price);
    }
  };

  // Calculate crypto equivalent
  const cryptoEquivalent = cryptoPrice ? totalPrice / cryptoPrice : 0;
  const gs50Equivalent = totalPrice / cryptoPrice / gs50Price; // This gives the amount of GS50 for the total price in ETH


  return (
    <div className="archive_section">
      <div className="products-container">
        {products.map(({ id, title, description, price, imgSrc, category, websiteUrl }) => (
          <div
            key={id}
            className={`product-card ${selectedProducts.some(p => p.id === id) ? 'selected' : ''}`}
            onClick={() => handleSelect(id)}
          >
            <img src={imgSrc} alt={title} className="product-image" />
            <div className="product-info">
              <h2 className="product-title">{title}</h2>
              <p className="product-price">${price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>

              <p className="product-description">{description}</p>
              <p className="product-category">{category}</p>
              {/* Visit Website button */}
      {websiteUrl && (
        <button className="pay-now-btn" onClick={(e) => {
          e.stopPropagation(); // Prevent the card selection handler from being triggered
          window.open(websiteUrl, '_blank', 'noopener,noreferrer');
        }}>
          Visit Website
        </button>
      )}
              
            </div>
          </div>
        ))}
      </div>

      <div className="payment-container">
        <div className="checkout-summary">
        <div className="total-price">
        Total Price: ${totalPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | {cryptoEquivalent.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} {networkSymbol}
      </div>
      <div className="gs50-equivalent">
      GS50 Price: {gs50Equivalent.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} GS50
    </div>
      

          <div className="items-selected">
            Items Selected: {selectedProducts.length}
          </div>
          <button className="pay-now-btn">Pay Now</button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
