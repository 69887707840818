import React, { useState, useEffect } from 'react';
import "../training/scss/index.scss"

const LoomVideos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch('/data-source/training-videos.json')
      .then(response => response.json())
      .then(data => {
        setVideos(data);
      })
      .catch(error => console.error('Error fetching video data:', error));
  }, []);

  return (
    <div className="grid-container">
      {videos.map((video, index) => (
        <div key={index} className="thumbnail">
          <iframe 
            src={video.url} 
            frameBorder="0" 
            allowFullScreen 
            title={video.title}
            style={{ width: '100%', height: 'auto', aspectRatio: '16 / 9' }}
          ></iframe>
          <div className="thumbnail-title">
            <h3>{video.title}</h3>
            <p>Trainer: {video.trainer}</p>
            <p>Category: {video.category}</p>
            <p>Time: {video.time}</p>
            <p>Knowledge Level: {video.knowledgeLevel}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoomVideos;
