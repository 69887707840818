const contractAddresses = {
    // Add your contract addresses for each chain here
    1: '0x9B4ea303Ca6302DFA46b73bC660598c65DE96B3d', // Ethereum Mainnet
    56: '0xC33E0b83D395FBBa0e2E463b10029F8379AD9c7e', // Binance Smart Chain
    137: '0xe7c15C988528baE6E2C6961E394f0ad663e52CB5', // Polygon/MATIC
    43114: '0x2234a5D9F716f5e39c84a5f8FF1D8DcA5A40328b', // Avalanche
    8453: '0xC8388A09B11325AF2F94bEefa4B7281359445789', // Base
    // Add more as needed
  };

  export default contractAddresses;