import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

const useCryptoPrice = () => {
  const { chainId } = useWeb3React(); // Get the current chain ID from Web3React context
  const [cryptoPrice, setCryptoPrice] = useState('Loading...'); // State to store the crypto price
  const [error, setError] = useState(null); // State to store any errors

  useEffect(() => {
    const fetchCryptoPrice = async () => {
      if (!chainId) {
        setError('Chain ID is undefined');
        return;
      }

      // Define a mapping of chain IDs to crypto IDs for CoinGecko and asset names/addresses for Mobula
      const cryptoMapping = {
        1: { coingecko: 'ethereum', mobula: 'Ethereum' }, // Ethereum
        56: { coingecko: 'binancecoin', mobula: 'binance' }, // Binance Smart Chain
        137: { coingecko: 'matic-network', mobula: 'Polygon' }, // Polygon
        8453: { coingecko: 'ethereum', mobula: 'Ethereum' }, // Polygon
        43114: { coingecko: 'avalanche-2', mobula: 'Avalanche' }, // Avalanche
        // Add other chain mappings as needed
      };

      const crypto = cryptoMapping[chainId];
      if (!crypto) {
        setError(`Unsupported Network: ${chainId}`);
        return;
      }

      // First, try to fetch from CoinGecko
      try {
        const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${crypto.coingecko}&vs_currencies=usd`);
        const data = await response.json();
        if (data[crypto.coingecko] && data[crypto.coingecko].usd) {
          setCryptoPrice(data[crypto.coingecko].usd);
          return;
        }
      } catch (fetchError) {
        console.error(`CoinGecko error for ${crypto.coingecko}:`, fetchError);
        // If CoinGecko fails, don't return; try Mobula next
      }

      // If CoinGecko fails or doesn't return data, fall back to Mobula
      try {
        // Note: Replace 'YOUR_MOBULA_API_KEY' with your actual Mobula API key
        const mobulaResponse = await fetch(`https://api.mobula.io/api/1/market/data?assets=${crypto.mobula}`, {
          headers: {
            'Authorization': `Bearer 	
            513b21cd-00ee-4e05-a760-4eac1976d474Y`
          }
        });
        const mobulaData = await mobulaResponse.json();
        if (mobulaData.data && mobulaData.data.price) {
          setCryptoPrice(mobulaData.data.price);
          return;
        } else {
          setError('No price data found in Mobula response');
        }
      } catch (mobulaError) {
        console.error(`Mobula error for ${crypto.mobula}:`, mobulaError);
        setError('Failed to fetch price from both CoinGecko and Mobula');
      }
    };

    fetchCryptoPrice();
  }, [chainId]); // Dependency array: Re-fetch when chainId changes

  return { cryptoPrice, error }; // Return the price and any error encountered
};

export default useCryptoPrice;
