import React from "react";
import { Header, Footer } from "../../layout";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./scss/index.scss";
import Booking from "../../assets/images/app-buttons/bookings-button.png";
import Dashboard from "../../assets/images/app-buttons/dashboard-button.png";
import Store from "../../assets/images/app-buttons/store-button.png";
import Income from "../../assets/images/app-buttons/income-button.png";
import Training from "../../assets/images/app-buttons/training-button.png";


export const MembersLanding = ({ history, visible }) => {
  return (
    <div className="members-landing-page">
    <Header visible={visible}/>
      <div className="levelMemb_section">
        <div className="nested_levelMemb">
          <div className="heading_content">
            <div className="icon-buttons">
              <Link to="/dashboard" className="icon-placeholder"><img src={Dashboard} alt="Dashboard" /></Link>
              <Link to="/training" className="icon-placeholder"><img src={Training} alt="Training" /></Link>
              <Link to="/store" className="icon-placeholder"><img src={Store} alt="Store" /></Link>
              <Link to="/opportunity" className="icon-placeholder"><img src={Income} alt="Income" /></Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};